import React from "react"
import NavBar from "../components/header/nav-main"
import Footer from "../components/footer"
import { Container, Row, Col } from "reactstrap"
import Form from "../components/hackathon/form"
import Solly from "../images/SolySignup.png"
import { AnimationWrapper } from 'react-hover-animation'
import "../css/hackathon.css"

const Hackathon = () => {
  return (
    <>
    <header>
      <NavBar />
    </header>
      <section id="resources" className="pa4">
      <Container>
        <Row>
          <Col xs={0} sm={0} md={0} lg={0} xl={1} />
          <Col xs={0} sm={0} md={0} lg={0} xl={1} />
          <Col xs={0} sm={0} md={0} lg={0} xl={1} />
          <Col xs={6} sm={6}>
            {/* <Form /> */}
            <AnimationWrapper>
              <a href="http://bit.ly/solace-community-register" target="_blank" className="solly"> <img src={Solly} alt="Logo" /> </a>
            </AnimationWrapper>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h2>Resources</h2>
          </Col>
        </Row>
        <Row>
        <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
            <a href="http://bit.ly/solace-tutorials" target="_blank">
              <div className="hackathon-card">
                <div className="title">Solace API Tutorials</div>
                <div className="desc">Get up-to-speed in sending and receiving messages over open
                  APIs and protocols, Solace Messaging APIs, and Pivotal
                  Platform.</div>
                <div className="link">Learn More >></div>
              </div>
            </a>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
            <a href="http://bit.ly/solace-docker-hackathon" target="_blank">
              <div className="hackathon-card">
                <div className="title">Getting Started With Docker</div>
                <div className="desc">
                  Getting Started with PubSub+ Standard Edition
                </div>
                <div className="link">Learn More >></div>
              </div>
            </a>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
            <a href="http://bit.ly/solace-cloud-hackathon" target="_blank">
              <div className="hackathon-card">
                <div className="title">Getting Started With Cloud</div>
                <div className="desc">
                  Getting Started with PubSub+ Cloud Edition
                </div>
                <div className="link">Learn More >></div>
              </div>
            </a>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
            <a href="http://bit.ly/solace-kotlin-hackathon" target="_blank">
              <div className="hackathon-card">
                <div className="title">React with Paho MQTT</div>
                <div className="desc">
                A react application using Paho MQTT that connects to Solace Cloud and publishes a message, receives the message through a subscription and displays it on the screen
                </div>
                <div className="link">Learn More >></div>
              </div>
            </a>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
            <a href="http://bit.ly/scs-basic-hackathon" target="_blank">
              <div className="hackathon-card">
                <div className="title">Spring Cloud Stream Basics</div>
                <div className="desc">
                Getting Started with Spring Cloud Stream
                </div>
                <div className="link">Learn More >></div>
              </div>
            </a>
          </Col>
          <Col xs={12} sm={12} md={6} lg={4} xl={4} className="mt4 mb3">
            <a href="http://bit.ly/python-mqtt-hackathon" target="_blank">
              <div className="hackathon-card">
                <div className="title">A Python application using Paho MQTT</div>
                <div className="desc">
                A Python application using the Solace Web Messaging API
                </div>
                <div className="link">Learn More >></div>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
      <Footer />
    </>
  )
}

export default Hackathon
